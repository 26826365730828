import { FC } from 'react';
import { useNavigate } from "react-router-dom";
import styles from './navigationButton.module.scss';

export type TNavigationButtonProps = {
    navigationPath: string,
    lable: string,
    active: boolean,
    setIsActivePage: () => void,
    closeMobileNavigation?: () => void
};

export const NavigationButton: FC<TNavigationButtonProps> = ({ navigationPath, lable, active, setIsActivePage, closeMobileNavigation }) => {
    const navigate = useNavigate()

    const navigateOnClick = () => {
        navigate(navigationPath);
        setIsActivePage();
        if (closeMobileNavigation) { closeMobileNavigation(); }
        window.scrollTo(0, 0);
    };

    return (
        <h5 className={`${styles.button} ${active ? styles.active : ""}`} onClick={navigateOnClick}> 
            {lable}
        </h5>
    );
};