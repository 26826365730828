import { FC, useEffect } from 'react';
import { useLocation } from "react-router-dom"
import styles from './navigation.module.scss';
import { NavigationButton } from "Components";

export type TNavigationProps = {
    isMobileNavigation?: boolean,
    closeMobileNavigation?: () => void,
    isActivePage: string,
    setIsActivePage: (location: string) => void,
};

export const Navigation: FC<TNavigationProps> = ({ isMobileNavigation, closeMobileNavigation, isActivePage, setIsActivePage }) => {
    // State
    const location = useLocation();

    useEffect (() => {
        // Set activePageView
        setIsActivePage(location.pathname);

     }, [location.pathname, setIsActivePage]);

    return (
        <div className={`${styles.navigationContainer} ${isMobileNavigation ? styles.mobile : ""}`}>
            <NavigationButton 
                lable="Wat?" 
                navigationPath="/" 
                active={isActivePage === "/" ? true : false} 
                setIsActivePage={() => setIsActivePage("/")} 
                closeMobileNavigation={closeMobileNavigation}
            />
            <NavigationButton 
                lable="Hoe?"   
                navigationPath="/hoe" 
                active={isActivePage === "/hoe" ? true : false} 
                setIsActivePage={() => setIsActivePage("/hoe")} 
                closeMobileNavigation={closeMobileNavigation}
            />
            <NavigationButton 
                lable="Voor wie?" 
                navigationPath="/voorWie" 
                active={isActivePage === "/voorWie" ? true : false} 
                setIsActivePage={() => setIsActivePage("/voorWie")} 
                closeMobileNavigation={closeMobileNavigation}
            />
        </div>
    );
};