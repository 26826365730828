import { FC, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from './header.module.scss';
import logo from "../../Images/logo2.svg";
import logoMobile from "../../Images/logoMobile.png";
import { Navigation } from "Components";

export type THeaderProps = {
    isWindowView: string
};

export const Header: FC<THeaderProps> = ({ isWindowView }) => {
    // state
    const [isMobileNavigationActive, setIsMobileNavigationActive] = useState<boolean>(false);
    const [isActivePage, setIsActivePage] = useState<string>("/");

    const navigate = useNavigate()

    const navigateOnClick = () => {
        navigate("/");
        setIsActivePage("/");
        window.scrollTo(0, 0);
    };

    return (
        <div className={styles.headerElement}>
            <div className={`${styles.headerContainer} ${isWindowView === 'desktop' ? styles.desktop : ""} ${isWindowView === "tablet" ? styles.tablet : ""} ${isWindowView === "mobile" ? styles.mobile : ""}`}>
                <img className={styles.logo} src={isWindowView === "mobile" ? logoMobile : logo} alt="Logo De Sleutelplaats" onClick={navigateOnClick}></img>
                <div className={`${styles.rightContainer} ${isWindowView === "mobile" ? styles.mobile : ""}`}>
                    {isWindowView !== "mobile" && (
                        <div className={styles.navigationContainer}>
                            <Navigation 
                                isActivePage={isActivePage}
                                setIsActivePage={setIsActivePage}
                            />
                        </div>
                    )}
                    <div className={styles.actionButton}>
                        <a className={styles.actionButtonBorder} href="https://forms.gle/jjGmW5BP8t27Uu7J8" target="_blank" rel="noreferrer">
                            <h5>Dit vind ik interessant!</h5>
                        </a>
                    </div>
                    {isWindowView === "mobile" && ( 
                        <FontAwesomeIcon className={styles.mobileNavigationIcon} icon={faBars} onClick={() => setIsMobileNavigationActive(true)} />
                    )}
                    {isMobileNavigationActive && isWindowView === "mobile" && (
                        <div className={styles.mobileNavigationContainer}>
                            <FontAwesomeIcon className={styles.closeMobileNavigationButton} icon={faXmark} onClick={() => setIsMobileNavigationActive(false)}/>
                            <Navigation 
                                isMobileNavigation={true} 
                                closeMobileNavigation={() => setIsMobileNavigationActive(false)}
                                isActivePage={isActivePage}
                                setIsActivePage={setIsActivePage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};