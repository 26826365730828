import { FC } from 'react';
import { IconDefinition as solidIconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from './socials.module.scss';

export type TSocialsProps = {
    icon: solidIconDefinition,
    webLink: string,
    isMobileView: boolean
};

export const Socials: FC<TSocialsProps> = ({ icon, webLink, isMobileView }) => {
    return (
        <a className={`${styles.socialsButton} ${isMobileView ? styles.mobile : ""}`} href={webLink} target="_blank" rel="noreferrer">
            <div className={styles.socialsButtonBorder}>
                <FontAwesomeIcon icon={icon} />
            </div>
        </a>        
    );
};