import { FC } from 'react';
import { faUser, faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from './contact.module.scss';

export type TContactProps = {
    contactName: string,
    phoneNumber: string,
    email: string
};

export const Contact: FC<TContactProps> = ({ contactName, phoneNumber, email }) => {
    return (
        <div className={styles.contactContainer}>
            <p className={styles.contactRow}>
                <FontAwesomeIcon icon={faUser} />
                {contactName}
            </p>
            <p className={styles.contactRow}>
                <FontAwesomeIcon icon={faPhone} />
                {phoneNumber}
            </p>
            <p className={styles.contactRow}>
                <FontAwesomeIcon icon={faEnvelope} />
                {email}
            </p>
        </div>
    );
};