import { FC, ReactNode } from 'react';
import styles from './quote.module.scss';
import quoteImageBorder from "../../../Images/quoteImageBorder.svg";

export type TQuoteProps = {
    isMobileView: boolean,
    children?: ReactNode,
    secondaryTwoStyle: boolean,
    quoteImage?: string
};

export const Quote: FC<TQuoteProps> = ({ isMobileView, children, secondaryTwoStyle, quoteImage }) => {
    return (
        <div className={`${styles.quoteSectionElement} ${isMobileView ? styles.mobile : ""} ${secondaryTwoStyle ? styles.secondaryTwo : ""} ${quoteImage && !isMobileView ? styles.withQuoteImage : ""}`}>
            {quoteImage &&  
                <div className={styles.quoteImageContainer}>
                    <img className={styles.quoteImage} src={quoteImage} alt="De Sleutelplaats"></img>
                    <img className={styles.quoteImageBorder} src={quoteImageBorder} alt="De Sleutelplaats"></img>
                </div>
            }
            <div className={`${styles.quoteSectionContainer} ${isMobileView ? styles.mobile : ""}`}>
                {children}
            </div>
        </div>
    );
};