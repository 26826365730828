import { FC } from 'react';
import { faFacebookF, faLinkedinIn, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faCopyright } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from './footer.module.scss';
import { Contact, Socials } from "Components";

export type TFooterProps = {
    isMobileView: boolean
};

export const Footer: FC<TFooterProps> = ({ isMobileView }) => {
    return (
        <div className={styles.footerSectionElement}>
            <div className={`${styles.footerSectionContainer} ${isMobileView ? styles.mobile : ""}`}>
                <div className={styles.column}>
                    <h2>Contact</h2>
                    <Contact 
                        contactName="Martijn Verkaar-Verheij"
                        phoneNumber="0620125762"
                        email="martijn@desleutelplaats.nl"
                    />
                    <Contact 
                        contactName="Ron van Klaveren"
                        phoneNumber="0643981475"
                        email="ron@desleutelplaats.nl"
                    />
                </div>
                <div className={styles.column}>
                    <h2>Volg ons op</h2>
                    <div className={styles.socialsContainer}>
                        <Socials 
                            icon={faLinkedinIn}
                            webLink="https://nl.linkedin.com/company/sleutelplaats"
                            isMobileView={isMobileView}
                        />
                        <Socials 
                            icon={faFacebookF}
                            webLink="https://www.facebook.com/profile.php?id=100093505941331"
                            isMobileView={isMobileView}
                        />
                        <Socials 
                            icon={faInstagram}
                            webLink="https://www.instagram.com/sleutelplaats/"
                            isMobileView={isMobileView}
                        />
                    </div>
                </div>
            
            </div>
            <div className={styles.copyrightContainer}><h6><FontAwesomeIcon icon={faCopyright} /> 2024 de Sleutelplaats</h6></div>
        </div>
    );
};