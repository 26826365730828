import { FC } from 'react';
import styles from './pages.module.scss';
import { Image, Content, Footer, Quote } from "Components";
import image from "../Images/landingImage.png";
import text from "../Images/landingText.svg";

export type TWatProps = {
    isMobileView: boolean,
    isWindowView: string
};

export const Wat: FC<TWatProps> = ({ isMobileView, isWindowView }) => {
    return (
        <div className={`${styles.pageContainer} ${isMobileView ? styles.mobile : ""}`}>
            <Image 
                isWindowView={isWindowView} 
                image={image}
                text={text}
            />
            <Content isMobileView={isMobileView} >
                <h1>Wat is De Sleutelplaats?</h1>
                <p>
                    De Sleutelplaats in Leiden is een creatief en technische werkplaats voor jongeren van 7 tot 27 jaar die zich willen ontwikkelen door te doen.
                    <br></br><br></br>
                    Om je talenten te ontwikkelen, heb je ruimte nodig om te mogen experimenteren. Een plek vol inspiratie om dingen te verkennen die je nog niet eerder hebt gedaan en waarvan je nog niet wist dat je ze kon. In ons huidige onderwijs- en zorgsysteem zijn er een veel jongeren die op een andere manier leren en hierdoor vastlopen. Daarmee gaat een hoop talent verloren, dat wel een plek nodig heeft.
                    <br></br><br></br>
                    Wij bieden jongeren een plek waar ze mogen en kunnen maken, met hun eigen interesses als uitgangspunt. Samen met vakmensen die als ‘meesters’ met de jongeren aan de slag gaan, ontwikkelen ze hun vaardigheden en talenten.
                </p>
            </Content>
            <Quote isMobileView={isMobileView} secondaryTwoStyle={true}>
                {isMobileView ?
                    <h1>
                        “De focus op
                    <br></br>
                        menswording in plaats
                    <br></br>
                        van resultaat”
                    </h1>
                :
                    <h1>
                        “De focus op menswording
                    <br></br>
                        in plaats van resultaat”
                    </h1>
                }
            </Quote>
            <Content isMobileView={isMobileView} secondaryStyle={true}>
                <h1>Creatief technisch talent ontwikkelen</h1>
                <p>
                    Ontwikkelen doet iedereen op een eigen manier. Toch is de diversiteit in aanbod voor jongeren om zich te ontwikkelen momenteel beperkt.
                    <br></br><br></br>
                    Er is een grote groep jongeren die graag leert door te doen. Voor het ontwikkelen van hun technische en creatieve talenten is onvoldoende ruimte. De kans op vastlopen en uitval is groot.
                    <br></br><br></br>
                    Ondertussen schreeuwt ook de werkvloer om technische en creatieve jongeren. Wij geloven wel dat deze jongeren er zijn, ze worden hierin alleen niet gestimuleerd.
                    <br></br><br></br>
                    Wij bieden jongeren de ruimte en vrijheid om hun ideeën te creëren, ontdekken wat ze kunnen en uitvinden welke interesses en talenten ze hebben.
                </p>
            </Content>
            <Quote isMobileView={isMobileView} secondaryTwoStyle={false}>
                {isMobileView ?
                    <h1>
                        “Als alles mogelijk is,
                        <br></br>
                        wat zou jij dan 
                        <br></br>
                        willen doen?”
                    </h1>
                :
                    <h1>
                        “Als alles mogelijk is,
                        <br></br>
                        wat zou jij dan willen doen?”
                    </h1>
                }
                
            </Quote>
            <Content isMobileView={isMobileView} >
                <h1>Wat doen we?</h1>
                <h2>Uitdagen</h2>
                <p>Als alles mogelijk is, wat zou jij dan willen doen? Een vraag waar jongeren vaak geen antwoord op weten, omdat ze niet meer groot durven te dromen. Wij dagen ze uit om dit wel weer te gaan doen. De autonomie van het kind is het uitgangspunt en de start is te gaan onderzoeken wat er nodig is om dit waar te maken.</p>
                <h2>Vertrouwen</h2>
                <p>Veel jongeren die vast zijn gelopen, zijn door deze tegenslagen het vertrouwen in hun eigen kunnen verloren.<br></br>Dat jongeren op school niet goed mee komen, betekent niet dat ze geen talent hebben. Door samen te werken met meesters met vakkennis, werken wij aan het geloof in deze talenten.</p>
                <h2>Ondersteunen</h2>
                <p>Naast de praktische kennis die je nodig hebt om te experimenteren en fouten te maken, vraagt het ook veel lef om dat te durven doen. Door naast de jongeren te gaan staan en samen te kijken wat er nodig is om hun ideeën waar te maken, bieden we ze de ondersteuning op zowel praktisch als mentaal vlak om te gaan durven doen.</p>
            </Content>
            <Footer isMobileView={isMobileView} />
        </div>
    );
};