import { FC, ReactNode } from 'react';
import styles from './content.module.scss';

export type TContentProps = {
    isMobileView: boolean,
    children?: ReactNode,
    secondaryStyle?: boolean
};

export const Content: FC<TContentProps> = ({ isMobileView, children, secondaryStyle }) => {
    return (
        <div className={`${styles.contentSectionElement} ${secondaryStyle ? styles.secondary : ""} ${isMobileView ? styles.mobile : ""}`}>
            <div className={styles.contentSectionContainer}>
                {children}
            </div>
        </div>
    );
};