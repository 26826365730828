import { FC } from 'react';
import styles from './pages.module.scss';
import { Content, Footer } from "Components";

export type TVoorWieProps = {
    isMobileView: boolean;
};

export const VoorWie: FC<TVoorWieProps> = ({ isMobileView }) => {
    return (
        <div className={styles.pageContainer}>
            <Content isMobileView={isMobileView} >
                <h1>Voor wie is De Sleutelplaats?</h1>
                <h2>Na school</h2>
                <p>Het technisch en creatief talent vind je niet alleen bij de jongeren die vast lopen in het onderwijs, maar ook bij de jongeren die na schooltijd uitgedaagd willen worden. Zij zijn na schooltijd welkom om hun talenten verder te ontwikkelen.</p>
                <h2>Tijdens school</h2>
                <p>Eén of twee dagen per week naar de Sleutelplaats, zodat je de andere dagen gewoon naar school kan, is een voorbeeld van de mogelijkheden. In overleg met de school zoeken we altijd naar mogelijkheden om te zorgen dat we leerlingen kunnen uitdagen én op school kunnen houden!</p>
                <h2>In plaats van school</h2>
                <p>Helaas is er ook een grote groep leerlingen die al vast is gelopen en die weinig tot geen onderwijs meer volgt. De ontwikkeling komt tot stilstand en het vetrouwen in zichzelf neemt sterk af. Voor deze jongeren bieden wij een plek waar ze dagdelen naar toe kunnen.</p>
            </Content>
            <Footer isMobileView={isMobileView} />
        </div>
    );
};