import { FC } from 'react';
import styles from './pages.module.scss';
import { Content, Footer, Quote } from "Components";
import image from "../Images/quoteImageHoe.png";

export type THoeProps = {
    isMobileView: boolean;
};

export const Hoe: FC<THoeProps> = ({ isMobileView }) => {
    return (
        <div className={styles.pageContainer}>
            <Content isMobileView={isMobileView} >
                <h1>Hoe doen we dat?</h1>
                <h2>Landen, kijken en luisteren</h2>
                <p>De jongeren die bij ons binnenkomen zijn vaak vastgelopen en hebben nog weinig vertrouwen in hun eigen kunnen. Ruimte geven om gewoon te zijn, te kijken, luisteren en aan te sluiten bij waar de jongere op dat moment aan toe is, is dan de eerste stap.</p>
                <h2>Doen!</h2>
                <p>Leren door te doen, is waar wij voor staan, dus tijd om te gaan doen! Erachter komen wat werkt en vooral te leren van wat niet werkt, is onze manier. Het plezier in maken staat hier voorop. Niet omdat het moet, maar omdat je zin hebt om iets te creëren.</p>
                <h2>Blijven ontwikkelen</h2>
                <p>Leren is een continu proces in ontwikkeling. Samen met vakmeesters optrekken en kennis delen, waarbij de jongere zelf de richting aangeeft en stappen zet.<br></br>Bij de Sleutelplaats blijven we ontwikkelen; onze jongeren, onszelf en als organisatie.</p>
            </Content>
            <Quote isMobileView={isMobileView} secondaryTwoStyle={true} quoteImage={image}>
                <h1>
                    “Meesterschap: 
                    <br></br>
                    Leren door vallen
                    <br></br>
                    en opstaan”
                </h1>
            </Quote>
            <Content isMobileView={isMobileView} secondaryStyle={true} >
                <h1>Leren door te doen!</h1>
                <h2>Plezier in maken</h2>
                <p>Door de creatieve en technische uitdaging krijgen de jongeren plezier in maken. Dit plezier is als een springplank voor nog meer mogelijkheden.</p>
                <h2>Creatief en technisch talent</h2>
                <p>Technisch en creatief talent is er in overvloed, we moeten de jongeren alleen wel de ruimte geven om dit te ontwikkelen! Door de begeleiding van vakmeesters groeit dit talent en komt het ook beschikbaar voor de technische en praktische beroepenvelden.</p>
                <h2>Welzijn</h2>
                <p>Een plek waar gewoon zijn, goed genoeg is.<br></br>Pas als je goed in je vel zit, kan je een ondernemende manier van denken gaan ontwikkelen. Met een ondernemende mindset groeit je veerkracht.<br></br>Vertrouwen in je eigen kunnen, ergens onderdeel van zijn en voelen dat je van betekenis bent, brengt je weer terug bij plezier in maken.</p>
            </Content>
            <Footer isMobileView={isMobileView} />
        </div>
    );
};