import { FC, useEffect, useState } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import styles from "./app.module.scss";
import { Wat } from "./Pages/Wat";
import { Hoe } from "./Pages/Hoe";
import { VoorWie} from "./Pages/VoorWie";
import { Header } from "Components";

export const App: FC = () => {
  // state
  const [isWindowView, setIswindowView] = useState<string>("mobile");

  useEffect (() => {
    // Set different windowViews acoording to the screen width
    const handleWindowView = () => {
      if (window.innerWidth < 768) {
        setIswindowView("mobile");
      } else if (window.innerWidth > 767 && window.innerWidth < 1280) {
        setIswindowView("tablet");
      } else if (window.innerWidth > 1279) {
        setIswindowView("desktop");
      }
    };
    // Handle windowView onload
    handleWindowView();

    // Handle windowView when resize
    window.addEventListener("resize", handleWindowView);

    return () => {
      window.removeEventListener("resize", handleWindowView);
    }

  }, []);
  
  return (
    <div className={styles.app}>
      <Router>
        <Header isWindowView={isWindowView} />
        <Routes>
          <Route path="/" element={<Wat isMobileView={isWindowView==="mobile"} isWindowView={isWindowView} />} />
          <Route path="/hoe" element={<Hoe isMobileView={isWindowView==="mobile"} />} />
          <Route path="/voorWie" element={<VoorWie isMobileView={isWindowView==="mobile"} />} />
        </Routes>
      </Router>
    </div>
  );
};
