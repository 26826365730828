import { FC } from 'react';
import styles from './image.module.scss';

export type TImageProps = {
    isWindowView: string,
    image: string,
    text: string
};

export const Image: FC<TImageProps> = ({ isWindowView, image, text }) => {
    return (
        <div className={`${styles.imageSectionElement} ${isWindowView === "tablet" ? styles.tablet : ""} ${isWindowView === "mobile" ? styles.mobile : ""}`}>
            <img className={styles.image} src={image} alt="De Sleutelplaats"></img>
            <div className={styles.textContainer}>
                <img className={styles.text} src={text} alt="De Sleutelplaats - Plezier in maken"></img>
            </div>
        </div>
    );
};